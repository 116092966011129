import TrackingContext, { TrackingProps } from 'contexts/trackingContext'
import { rem } from 'polished'
import React, { useCallback, useContext, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import * as Analytics from 'analytics/analytics'
import CloseButton from 'components/Common/CloseButton'
import Image from 'components/Common/Image'
import OfferLabels from 'components/Common/Labels/OfferLabels'
import Group from 'components/utils/Group'
import BodyText from 'components/Luxkit/Typography/BodyText'
import Caption from 'components/Luxkit/Typography/Caption'
import { mediaQueryUp } from 'components/utils/breakpoint'
import GeoContext from 'contexts/geoContext'
import Clickable from 'components/Common/Clickable/Clickable'
import { useInView } from 'react-intersection-observer'
import { CAROUSEL_CARD_WIDTH } from 'components/OfferList/OfferCards/OfferCardSmall/CarouselCardSmall'
import config from 'constants/config'
import PriceRowPrice from 'components/Luxkit/PricePoints/PriceRowPrice'
import PriceRowPriceCaption from 'components/Luxkit/PricePoints/PriceRowPriceCaption'
import { KEYBOARD_MODE_CSS_VAR } from 'constants/app'
import { getLereListSource } from 'components/Recommendations/common/constant'
import { useAppSelector } from 'hooks/reduxHooks'
import { getOptimizelyExperimentVariation } from 'lib/optimizely/optimizelyUtils'
import { OptimizelyExperiments } from 'constants/optimizely'
import { safeDivideAndCeil } from 'lib/maths/mathUtils'
import { mediaHoverable } from 'lib/theme/mediaQueries'
import LuxPlusPriceGuard from 'luxPlus/components/LuxPlusPriceGuard'
import PriceRowLuxPlusPrice from 'components/Luxkit/PricePoints/LuxPlus/PriceRowLuxPlusPrice'
import { connect } from 'react-redux'
import { checkCanViewLuxPlusBenefits } from 'luxPlus/selectors/featureToggle'
import { productClickEventWithContext, impressionEventWithContext } from 'analytics/snowplow/events'
import useOffer from 'hooks/Offers/useOffer'

const ImageBlock = styled.div`
  flex-shrink: 0;
  width: ${rem(68)};
  height: ${rem(92)};
`

const Root = styled(Clickable)`
  align-self: start;
  background-color: ${props => props.theme.palette.neutral.default.eight};
  overflow: hidden;
  transition: outline 0.2s, background-color 0.2s;

  width: 75vw;

  // on tablet we match the offer card size
  ${mediaQueryUp.tablet} {
    width: ${rem(CAROUSEL_CARD_WIDTH)};
  }

  ${mediaQueryUp.desktop} {
    padding: ${rem(8)};
    // the width is unset since the card carousel should auto size it
    width: unset;
    // the min-width is used for new home page where the full width carousel does not control tile width
    min-width: ${rem(320)};

    // on desktop, close button is hidden unless hover
    .high-intent-close-button {
      transition: opacity 0.2s;
      opacity: 0;
      pointer-events: none;
    }

    ${mediaHoverable} {
      &:hover {
        background-color: ${props => props.theme.palette.neutral.default.seven};

        .high-intent-close-button {
          pointer-events: auto;
          opacity: 1;
        }
      }
    }
  }

  &.tile-border {
    border: 1px solid ${props => props.theme.palette.neutral.default.five};
    border-radius: ${props => props.theme.borderRadius.XS};
  }

  &:focus {
    outline: var(${KEYBOARD_MODE_CSS_VAR}, 2px solid  ${props => props.theme.palette.neutral.default.five});
    outline-offset: var(${KEYBOARD_MODE_CSS_VAR}, 2px);
  }
`

const ContentGroup = styled(Group)`
  flex-grow: 1;
`

interface MappedProps {
  canViewLuxPlusBenefits?: boolean;
}

export interface HighIntentTileProps extends MappedProps {
  offerId: string;
  highIntentCategory: App.HighIntentCategory;
  url: string;
  title: string;
  image: App.Image;
  checkInOutText?: string;
  occupancyText?: string;
  urgencyLabels?: Array<App.OfferUrgencyLabel>;
  durationText?: string;
  duration: number | undefined;
  displayedPrice?: number;
  isMemberPrice: boolean;
  discountPercentage?: number;
  position: number;
  tracking?: TrackingProps;
  testId: string;
  onClick?: (offerId: string) => void
  onClose?: (offerId: string, category: App.HighIntentCategory) => void
  className?: string;
}

function HighIntentRecommendationTile(props: HighIntentTileProps) {
  const {
    offerId,
    highIntentCategory,
    url,
    title,
    image,
    checkInOutText,
    occupancyText,
    urgencyLabels,
    durationText,
    duration,
    displayedPrice,
    isMemberPrice,
    discountPercentage,
    position,
    tracking,
    testId,
    onClick,
    onClose,
    className,
    canViewLuxPlusBenefits,
  } = props

  const { currentCurrency } = useContext(GeoContext)

  // ------- Render props -------------
  const showPrice = (!!displayedPrice && displayedPrice > 0)
  const checkInOutAndOccupancyText = [checkInOutText, occupancyText].filter((str) => str).join(', ')

  // if there is no date/occupancy, we can use 2 lines for the title
  const titleLines = checkInOutAndOccupancyText ? 1 : 2
  // we want to use letter wrap so the single line clamp can break mid-word
  const titleWrap: React.ComponentProps<typeof Caption>['wrap'] = (titleLines === 1) ? 'letter-wrap' : undefined

  // ------- Analytics -------------
  const [inViewRef, inView] = useInView({ triggerOnce: true })

  const trackingCtx = useContext(TrackingContext)
  const trackingProps = useMemo(() => ({
    ...trackingCtx,
    ...tracking, // explicit tracking props override inherited tracking context
  }), [tracking, trackingCtx])
  const hasTracking = !!trackingCtx || !!tracking

  const listId = trackingProps?.listId ?? 'unknown'
  const listName = trackingProps?.listName ?? 'High Intent Recommendations'
  const category = trackingProps?.category ?? 'unknown'
  const lereVersion = getLereListSource(trackingProps?.lere?.version)
  const [offer] = useOffer(offerId)

  useEffect(() => {
    if (inView && hasTracking && offer && tracking?.listId) {
      Analytics.trackEvent(impressionEventWithContext(position, listName, tracking.listId, offer))
    }
  }, [inView, hasTracking, offerId, title, category, displayedPrice, position, listName, offer, currentCurrency, listId, lereVersion, tracking?.listId])

  const fireProductClick = useCallback(() => {
    if (!hasTracking) { return }

    if (offer && tracking?.listId) {
      Analytics.trackEvent(productClickEventWithContext(position, listName, tracking?.listId, offer))
    }
  }, [hasTracking, offer, tracking?.listId, position, listName])
  const handleClick = useCallback(() => {
    fireProductClick()
    onClick?.(offerId)
  }, [fireProductClick, onClick, offerId])

  const handleDismissed = useCallback((event: React.MouseEvent) => {
    event.preventDefault()
    event.stopPropagation()
    onClose?.(offerId, highIntentCategory)
    if (hasTracking) {
      Analytics.trackClientEvent({
        subject: `${listId} ${offerId}`,
        action: `${highIntentCategory}_tile_dismissed`,
        category: 'lere',
        type: 'interaction',
      })
    }
  }, [onClose, hasTracking, listId, offerId, highIntentCategory])

  const linkParams = useMemo(() => {
    return {
      onClick: handleClick,
      'data-testid': testId,
      to: url,
      ...(config.OPEN_NEW_TAB_OFFER_CLICK ? { target: '_blank' } : {}),
    }
  }, [handleClick, testId, url])

  const isPerNightPricingTestEnabled = !!useAppSelector((state: App.State) => getOptimizelyExperimentVariation(state, OptimizelyExperiments.pricePerNight))
  const showPerNight = isPerNightPricingTestEnabled && displayedPrice && duration
  const saleUnit = showPerNight ? 'night' : 'total'
  const totalPrice = showPerNight ? safeDivideAndCeil(displayedPrice, duration) : displayedPrice

  return (
    <Root {...linkParams} ref={inViewRef} className={className}>
      <Group direction="horizontal" verticalAlign="stretch" fullWidth gap={12}>
        <ImageBlock>
          <Image
            id={image.id}
            alt={image.title}
            width={68}
            height={92}
            dpr={2}
            fit="center"
          />
        </ImageBlock>
        <ContentGroup direction="vertical" gap={4} verticalAlign="space-between">
          <Group direction="horizontal" fullWidth gap={12}>
            <Group direction="vertical" fullWidth gap={4}>
              <BodyText variant="medium" weight="bold" lineClamp={titleLines} wrap={titleWrap}>{title}</BodyText>
              {!!checkInOutAndOccupancyText && !urgencyLabels?.length && <Caption variant="large" lineClamp={1} wrap="letter-wrap">
                {checkInOutAndOccupancyText}
              </Caption>}
              {!!urgencyLabels?.length && <OfferLabels urgencyLabels={urgencyLabels} countDownShowHourOnly />}
            </Group>
            {onClose && <CloseButton
                className="high-intent-close-button"
                onClick={handleDismissed}
              />}
          </Group>
          {showPrice && totalPrice && <div>
            {!!durationText && <PriceRowPriceCaption>{durationText}</PriceRowPriceCaption>}
            <LuxPlusPriceGuard
              showMemberPrice={isMemberPrice && canViewLuxPlusBenefits}
              fallbackPrice={<PriceRowPrice
                size="S"
                price={totalPrice}
                saleUnit={saleUnit}
                discountPercentage={discountPercentage}
              />}
            >
              <PriceRowLuxPlusPrice
                memberPrice={totalPrice}
                saleUnit={saleUnit}
                size="S"
                discountPercentage={discountPercentage}
              />
            </LuxPlusPriceGuard>
          </div>}
        </ContentGroup>
      </Group>
    </Root>
  )
}

const mapStateToProps = (state: App.State): MappedProps => ({
  canViewLuxPlusBenefits: checkCanViewLuxPlusBenefits(state),
})

export default connect(mapStateToProps)(HighIntentRecommendationTile)
